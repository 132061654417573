import React from "react";

import Navbar from "../../components/navbar";

import "./style.scss";

const Home = () => {
  return (
    <>
      <Navbar />
      <div className="home-container">
        <div className="home-background">
          {/* <p className="tagline">Concept to <br/><span className="tagline-custom">Reality</span></p> */}
          <p className="tagline">
            <span className="tagline-primary">Architecture Code</span>
            {/* <br /> */}
            <span className="tagline-secondary">Concept to Reality</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default Home;
