import React from "react";
import ServicesCard from "../../components/services-card";

import civilInteriorIcon from "../../assets/icons/civil-icon-wo-bg.png";
import electricalIcon from "../../assets/icons/electrical-icon-wo-bg.png";
import ehsIcon from "../../assets/icons/ehs-icon-wo-bg.png";
import mepIcon from "../../assets/icons/mep-icon-wo-bg.png";
import networkingIcon from "../../assets/icons/networking-icon-wo-bg.png";

import "./style.scss";

const Services = () => {
  return (
    <div className="services-container" id="services">
      <div className="heading">Services</div>
      <div className="services-items-container">
        <ServicesCard
          serviceName="Civil & Interior"
          serviceLogo={civilInteriorIcon}
        />
        <ServicesCard serviceName="Electrical" serviceLogo={electricalIcon} />
        <ServicesCard serviceName="EHS Projects" serviceLogo={ehsIcon} />
        <ServicesCard serviceName="MEP Works" serviceLogo={mepIcon} />
        <ServicesCard serviceName="Networking" serviceLogo={networkingIcon} />
      </div>
    </div>
  );
};

export default Services;
