import React from "react";

import addressIcon from "../../assets/icons/location-icon.png";
import mailIcon from "../../assets/icons/mail-icon.png";
import phoneIcon from "../../assets/icons/telephone-icon.png";
import MapContainer from "../../components/map-container";

import "./style.scss";
import GoogleMapContainer from "../../components/google-map-container";

const ContactUs = () => {
  return (
    <div className="contact-us-container" id="contact-us">
      <div className="heading">Contact Us</div>
      <div className="contact-us-details">
        <div className="contact-us-item">
          <img src={addressIcon} alt="Location" className="contact-icons" />
          Athanikkal, Manjeri, Malappuram, Kerala 673642
        </div>
        <div className="contact-us-item">
          <img src={mailIcon} alt="E-Mail" className="contact-icons" />
          sales@architecturecode.com
        </div>
        <div className="contact-us-item">
          <img src={phoneIcon} alt="Phone" className="contact-icons" />
          +91-75948 82411
        </div>
      </div>
      <div className="map-container">
        {/* <MapContainer /> */}
        <GoogleMapContainer />
      </div>
    </div>
  );
};

export default ContactUs;
