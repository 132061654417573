import React from "react";

import flipkartLogo from "../../assets/flipkart-logo.png";
import ekartLogo from "../../assets/ekart-logo.png";
import inkedCorridorsLogo from "../../assets/inked-corridors-logo.jpeg";
import sudharmaLogo from "../../assets/sudharma-laboratory-logo.png";
import scoopsoLogo from "../../assets/scoopso-logo.png";
import jeevesLogo from "../../assets/jeeves-logo.svg";
import f1Logo from "../../assets/f1-logo.jpeg";
import delhiveryLogo from "../../assets/delhivery-logo.jpg";
import mcdLogo from "../../assets/mcd-logo.jpg";

import "./style.scss";

const Clients = () => {
  return (
    <div className="clients-container" id="clients">
      <div className="heading">Clients</div>
      <div className="clients-logos">
        <img
          className="logo-item logo-flipkart"
          src={flipkartLogo}
          alt="Flipkart"
        />
        <img className="logo-item" src={ekartLogo} alt="E-kart Logistics" />
        <img
          className="logo-item logo-delhivery"
          src={delhiveryLogo}
          alt="Delhivery"
        />
       
        <img className="logo-item logo-jeeves" src={jeevesLogo} alt="Jeeves" />
        <img className="logo-item" src={f1Logo} alt="F1" />
        <img
          className="logo-item logo-mcd"
          src={mcdLogo}
          alt="Mcdonalds"
        />
        <img
          className="logo-item logo-scoopso"
          src={scoopsoLogo}
          alt="Scoopso"
        />
         <img
          className="logo-item logo-inked-corridors"
          src={inkedCorridorsLogo}
          alt="Inked Corridors"
        />
        <img
          className="logo-item logo-sudharma"
          src={sudharmaLogo}
          alt="Sudharma Labs"
        />
      </div>
    </div>
  );
};

export default Clients;
