import React from "react";

import "./style.scss";

const ServicesCard = ({ serviceLogo, serviceName }) => {
  return (
    <div className="services-card-container">
      <img src={serviceLogo} alt={serviceName} className="services-logo" />
      <p className="services-label">{serviceName}</p>
    </div>
  );
};

export default ServicesCard;
