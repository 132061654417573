import { GoogleMap, MarkerF, useLoadScript, InfoWindowF } from "@react-google-maps/api";
import { useMemo, useState } from "react";

const mapContainerStyles = {
    position: "relative",
    width: "100%",
    height: "400px",
};

const LAT = 11.11777;
const LNG = 76.04859;

const GoogleMapContainer = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [infoWindowData, setInfoWindowData] = useState();

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    const center = useMemo(() => ({ lat: LAT, lng: LNG }), []);

    const handleMarkerClick = (lat, lng, address) => {
        setInfoWindowData({ address });
        setIsOpen(true);
    };

    return (
        <div className="App">
            {!isLoaded ? (
                <h1>Loading...</h1>
            ) : (
                <GoogleMap
                    mapContainerClassName="map-container"
                    mapContainerStyle={mapContainerStyles}
                    center={center}
                    zoom={14}
                >
                    <MarkerF
                        position={{ lat: LAT, lng: LNG }}
                        onClick={() => {
                            handleMarkerClick(LAT, LNG, 'Architecture Code');
                        }}
                    >
                        {isOpen && (
                            <InfoWindowF
                                onCloseClick={() => {
                                    setIsOpen(false);
                                }}
                            >
                                <>
                                    <h3 style={{ color: '#000000', margin: 0 }}>{infoWindowData.address}</h3>
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href="https://www.google.com/maps/dir/?api=1&destination=Architecture%20Code%20Manjeri"
                                    >
                                        Get Directions
                                    </a>
                                </>
                            </InfoWindowF>
                        )}
                    </MarkerF>
                </GoogleMap>
            )}
        </div>
    );
};

export default GoogleMapContainer;
