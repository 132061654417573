import React from "react";

import "./style.scss";

const AboutUs = () => {
  return (
    <div className="about-us-container" id="about-us">
      <div className="heading">About Us</div>
      <div className="content-section">
        <div className="content">
          It is an Architectural and Interior firm with the team of passionate
          Architects, Designers & Engineers. A multidisciplinary platform
          offering architectural, interior, turnkey, fit out and landscaping
          projects. We believe in the power of design to elevate people's lives
          and we are committed to doing everything necessary to deliver
          environments that outperform our client's expectations, now and in the
          future. Our motto is Ideas to Reality.
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
