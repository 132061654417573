import React from "react";

import Home from "../../sections/home";
import AboutUs from "../../sections/about-us";
import Services from "../../sections/services";
import Clients from "../../sections/clients";
import Gallery from "../../sections/gallery";
import ContactUs from "../../sections/contact-us";
import Footer from "../../sections/footer";

const HomeScreen = () => {
  return (
    <div>
      <Home />
      <AboutUs />
      <Services />
      <Clients />
      <Gallery />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default HomeScreen;
