import React, { useState } from "react";

import logo from "../../assets/logo-wo-bg.png";

import "./style.scss";

const Navbar = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return (
    <nav className="navigation">
      <a href="/" className="brand-name">
        <img src={logo} alt="Logo" className="home-logo" />
      </a>
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
      >
        <ul>
          <li>
            <a
              href="#about-us"
              onClick={() => {
                setIsNavExpanded(!isNavExpanded);
              }}
            >
              About Us
            </a>
          </li>
          <li>
            <a
              href="#services"
              onClick={() => {
                setIsNavExpanded(!isNavExpanded);
              }}
            >
              Services
            </a>
          </li>
          <li>
            <a
              href="#clients"
              onClick={() => {
                setIsNavExpanded(!isNavExpanded);
              }}
            >
              Clients
            </a>
          </li>
          <li>
            <a
              href="#gallery"
              onClick={() => {
                setIsNavExpanded(!isNavExpanded);
              }}
            >
              Gallery
            </a>
          </li>
          <li>
            <a
              className="contact-menu"
              href="#contact-us"
              onClick={() => {
                setIsNavExpanded(!isNavExpanded);
              }}
            >
              Contact Us
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
