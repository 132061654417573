import React from "react";

import galleryImage1 from "../../assets/gallery/1fef4e7e-0eb7-4498-a30f-5c081f133d38.jpg";
import galleryImage2 from "../../assets/gallery/eb0e6659-0b32-43a4-ad6a-03e6dc04e55b.jpg";
import galleryImage3 from "../../assets/gallery/156ae7c9-7d3e-4193-b8a4-0319cae9a677.jpg";
import galleryImage4 from "../../assets/gallery/e24a8817-65a5-434b-9844-0e6d89ac3949.jpg";
import galleryImage5 from "../../assets/gallery/7ff9e43a-93c0-40c0-9267-a63a3ce690c0.jpg";

import "./style.scss";

const Gallery = () => {
  return (
    <div className="gallery-container" id="gallery">
      <div className="heading">Gallery</div>
      <div className="gallery-section">
        <div className="section-one">
          <img src={galleryImage4} alt="4" className="section-one-item" />
          <div className="section-one-a">
            <img src={galleryImage1} alt="1" className="section-one-item" />
            <img src={galleryImage2} alt="2" className="section-one-item" />
          </div>
        </div>
        <div className="section-two">
          <img src={galleryImage3} alt="3" className="section-one-item" />
          <img src={galleryImage5} alt="5" className="section-one-item" />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
